import React, { useState, useEffect, useMemo } from "react";
import { TextField, Button, Grid, Paper } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function SearchModule(props) {
  //   const [country, setCountry] = useState([]);
  const [specialty, setSpecialty] = useState();
  const [provider, setProvider] = useState("");
  const [language, setLanguage] = useState("es");
  const { t, changeDirectLanguage } = useBDocTranslator();

  useEffect(() => {
    changeDirectLanguage(props?.language);
  }, []);

  const specialtiesSorted = useMemo(() => {
    if (props.speciality_name) return [];
    const res = props.specialties
      ?.map((i) => ({ ...i, speciality_name: t(i.speciality_name) }))
      .sort((a, b) => a.speciality_name.localeCompare(b.speciality_name));
    return res;
  }, [props.specialties]);

  return (
    <Paper elevation={1} className="p-3 mb-4" variant={"elevation"}>
      <Grid container spacing={2} justify="center">
        {/* Search by Country */}
        {/* <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        id="search-by-country"
                        onChange={(e, newValue) => setCountry(newValue)}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label={t("Filter by Country")} variant="outlined" />
                        )}
                    />
                </Grid> */}

        {/* Search by Language */}
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            id="search-by-language"
            onChange={(e, newValue) => setLanguage(newValue)}
            options={languages}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} variant="outlined" />
            )}
          />
        </Grid>

        {/* Search By Specialty */}
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            id="search-by-specialty"
            onChange={(e, newValue) => setSpecialty(newValue)}
            options={specialtiesSorted}
            getOptionLabel={(option) => t(option.speciality_name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Specialty")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        {/* Search by Providers name */}
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            id="search-by-name"
            onChange={(e, newValue) => setProvider(newValue)}
            options={props.providers}
            getOptionLabel={(option) =>
              `${option.firstName || ""} ${option.lastname || ""}${
                option?.medical_speciality?.speciality_name
                  ? ` - ${t(option?.medical_speciality?.speciality_name)}`
                  : ""
              }`
            }
            renderInput={(params) => (
              <TextField {...params} label={t("Provider")} variant="outlined" />
            )}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              const findPropSpecialty = props.specialties.find(
                (i) => i.id === specialty?.id
              );
              props.handleSearch(language, findPropSpecialty, provider);
            }}
            variant="contained"
            color="primary"
          >
            {t("Search")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

const countries = [
  { name: "United States", code: "USA" },
  { name: "Costa Rica", code: "CRC" },
  { name: "Bahamas", code: "BHA" },
  { name: "Jamaica", code: "JAM" },
  { name: "Nicaragua", code: "NIC" },
];

const languages = [
  { name: "English", code: "en" },
  { name: "Español", code: "es" },
];
