import React, { useState } from "react";
import { AppBar, Toolbar, Typography, InputBase, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const RecipeNavbar = ({ onSearch }) => {
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = () => {
        onSearch(searchQuery);
    };

    return (
        <AppBar position="fixed"> {/* Add "fixed" position */}
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Recipe Search
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <InputBase
                        placeholder="Search…"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSearch();
                            }
                        }}
                    />
                    <IconButton onClick={handleSearch} color="inherit">
                        <SearchIcon />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default RecipeNavbar;
