import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HistoryIcon from "@material-ui/icons/History";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import swal from "sweetalert";
import useBDocTranslator from "../../../hooks/useBDocTranslator";
import DashboardAdmin from "../../DashboardAdmin";
import useUserState from "../../../hooks/useUserState";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "50px",
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  titleContainer: {
    marginBottom: theme.spacing(3),
  },
  approveButton: {
    backgroundColor: "#4caf50",
    color: "white",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
    marginRight: theme.spacing(1),
  },
  historyButton: {
    backgroundColor: "#2196f3",
    color: "white",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  statusActive: {
    color: "green",
    fontWeight: "bold",
  },
  statusInactive: {
    color: "red",
    fontWeight: "bold",
  },
  statusPending: {
    color: "orange",
    fontWeight: "bold",
  },
}));

export default function PendingBeneficiaries() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pendingBeneficiaries, setPendingBeneficiaries] = useState([]);
  const [beneficiaryHistory, setBeneficiaryHistory] = useState([]);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [userLocalState] = useUserState();
  const { t } = useBDocTranslator();

  // Fetch pending beneficiaries
  const fetchPendingBeneficiaries = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/affiliations/beneficiaries/pending`,
        {
          headers: {
            Authorization: `Bearer ${userLocalState.jwt}`,
          },
        }
      );
      setPendingBeneficiaries(response.data);
    } catch (error) {
      console.error("Error fetching pending beneficiaries:", error);
      swal("Error", "Could not load pending beneficiaries", "error");
    } finally {
      setLoading(false);
    }
  };

  // Approve a beneficiary
  const handleApproveBeneficiary = async (beneficiaryId) => {
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/affiliations/beneficiaries/approve/${beneficiaryId}`,
        { status: "active" },
        {
          headers: {
            Authorization: `Bearer ${userLocalState.jwt}`,
          },
        }
      );
      swal("Success", "Beneficiary has been approved", "success");
      // Refresh the list after approval
      fetchPendingBeneficiaries();
    } catch (error) {
      console.error("Error approving beneficiary:", error);
      swal("Error", "Could not approve the beneficiary", "error");
    } finally {
      setLoading(false);
    }
  };

  // Get beneficiary history
  const handleViewHistory = async (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/affiliations/beneficiaries/history/${beneficiary.patient_id}`,
        {
          headers: {
            Authorization: `Bearer ${userLocalState.jwt}`,
          },
        }
      );
      setBeneficiaryHistory(response.data);
      setHistoryModalOpen(true);
    } catch (error) {
      console.error("Error fetching beneficiary history:", error);
      swal("Error", "Could not load beneficiary history", "error");
    } finally {
      setLoading(false);
    }
  };

  // Format status with color
  const renderStatus = (status) => {
    switch (status) {
      case "active":
        return <span className={classes.statusActive}>{status}</span>;
      case "inactive":
        return <span className={classes.statusInactive}>{status}</span>;
      case "pending":
        return <span className={classes.statusPending}>{status}</span>;
      default:
        return <span>{status}</span>;
    }
  };

  useEffect(() => {
    fetchPendingBeneficiaries();
  }, []);

  return (
    <DashboardAdmin>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={classes.titleContainer}>
        <Typography variant="h4" component="h2">
          {t("Pending Plan Beneficiaries")}
        </Typography>
        <Typography variant="body1">
          {t("Approve beneficiaries who have pending status in plan affiliations")}
        </Typography>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={fetchPendingBeneficiaries}
        disabled={loading}
      >
        {t("Refresh List")}
      </Button>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="pending beneficiaries table">
          <TableHead>
            <TableRow>
              <TableCell>{t("ID")}</TableCell>
              <TableCell>{t("Beneficiary Name")}</TableCell>
              <TableCell>{t("Plan Holder")}</TableCell>
              <TableCell>{t("Plan Name")}</TableCell>
              <TableCell>{t("Relationship")}</TableCell>
              <TableCell>{t("Status")}</TableCell>
              <TableCell>{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingBeneficiaries.length > 0 ? (
              pendingBeneficiaries.map((beneficiary) => (
                <TableRow key={beneficiary.id}>
                  <TableCell>{beneficiary.id}</TableCell>
                  <TableCell>
                    {`${beneficiary.patient?.firstName || ''} ${beneficiary.patient?.lastname || ''}`}
                  </TableCell>
                  <TableCell>
                    {`${beneficiary.affiliation?.patient?.firstName || ''} ${beneficiary.affiliation?.patient?.lastname || ''}`}
                  </TableCell>
                  <TableCell>{beneficiary.affiliation?.plan?.name || ''}</TableCell>
                  <TableCell>{beneficiary.patient?.relationship || ''}</TableCell>
                  <TableCell>
                    <span style={{ color: "orange", fontWeight: "bold" }}>
                      {beneficiary.status}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className={classes.approveButton}
                      startIcon={<CheckCircleOutlineIcon />}
                      onClick={() => handleApproveBeneficiary(beneficiary.id)}
                    >
                      {t("Approve")}
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.historyButton}
                      startIcon={<HistoryIcon />}
                      onClick={() => handleViewHistory(beneficiary)}
                    >
                      {t("View History")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {loading
                    ? t("Loading...")
                    : t("No pending beneficiaries found")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* History Modal */}
      <Dialog
        open={historyModalOpen}
        onClose={() => setHistoryModalOpen(false)}
        aria-labelledby="beneficiary-history-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="beneficiary-history-dialog-title">
          {t("Beneficiary Plan History")} - {selectedBeneficiary?.patient?.firstName} {selectedBeneficiary?.patient?.lastname}
        </DialogTitle>
        <DialogContent>
          {beneficiaryHistory.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Plan Name")}</TableCell>
                    <TableCell>{t("Plan Holder")}</TableCell>
                    <TableCell>{t("Status")}</TableCell>
                    <TableCell>{t("Last Updated")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {beneficiaryHistory.map((history) => (
                    <TableRow key={history.id}>
                      <TableCell>{history.affiliation?.plan?.name || ''}</TableCell>
                      <TableCell>
                        {`${history.affiliation?.patient?.firstName || ''} ${history.affiliation?.patient?.lastname || ''}`}
                      </TableCell>
                      <TableCell>
                        {renderStatus(history.status)}
                      </TableCell>
                      <TableCell>
                        {new Date(history.updated_at).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <DialogContentText>
              {t("No history found for this beneficiary")}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setHistoryModalOpen(false)} 
            color="primary"
          >
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardAdmin>
  );
}