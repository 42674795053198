import React, { useEffect, useState } from "react";
import DashboardMUI from "../dashboard/DashboardMui";
import providerIcon from "../../images/doctor.svg";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UseUserState from "../hooks/useUserState";
import Axios from "axios";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slider from "@material-ui/core/Slider";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import red from "@material-ui/core/colors/red";
import { createTheme } from "@material-ui/core/styles";
import "../../styles/customs.css";
import LoadingSpin from "../utils/Loading";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import swal from "sweetalert";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  buttonDelete: {
    position: "absolute",
    right: "5px",
  },
  buttonEdit: {
    position: "absolute",
    right: "40px",
  },
  accordion: {
    [theme.breakpoints.down("xs")]: {
      width: "400px",
    },
  },
}));

const theme = createTheme({
  primary: {
    main: red[500],
  },
  secondary: {
    main: "#f44336",
  },
});

export default function ProviderServiceSettings() {
  const classes = useStyles();
  const storeState = UseUserState();
  const [services, setServices] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [visibility, setVisibility] = useState("");
  const [code, setCode] = useState("");
  const [serviceDuration, setServiceDuration] = useState("");
  const [edit, setEdit] = useState("");
  const secondary = red["500"];
  const [expanded, setExpanded] = useState(false);
  const [editName, setEditName] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editCode, setEditCode] = useState("");
  const [editVisibility, setEditVisibility] = useState();
  const [open, setOpen] = useState(false);
  const [countClick, setCountClick] = useState(0);
  const [editDuration, setEditDuration] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveEdit, setLoadingSaveEdit] = useState(false);
  const [openSnackbarS, setOpenSnackbarS] = useState(false);
  const [openSnackbarF, setOpenSnackbarF] = useState(false);
  const [openSnackbarD, setOpenSnackbarD] = useState(false);
  const [description, setDescription] = useState("");
  const [editDescription, setEditDescription] = useState("");
  //const [serviceScope, setServiceScope] = useState(1); // 1= Public ; 2= Private
  const { t } = useBDocTranslator();

  const clearForm = (e) => {
    setName("");
    setPrice("");
    setServiceDuration(15);
    setDescription("");
  };

  const findServices = (e) => {
    if (storeState[0].jwt) {
      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/services/findByProvider?provider_id=${storeState[0].extendedData.id}`,
        {
          headers: {
            Authorization: "Bearer " + storeState[0].jwt,
          },
        }
      )
        .then((res) => {
          setServices(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const handleSave = () => {
    setLoadingSave(true);
    handleCode();
    if (name && visibility && price && serviceDuration && code) {
      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/services/createService`,
        {
          code,
          provider_id: storeState[0].extendedData.id,
          name,
          description: description,
          scope: "1",
          created_by: storeState[0].user.id,
          price,
          duration_minute: serviceDuration,
          appointment_type_id: "1", // TODO: Implement the rest of appointments types, here are only CONS
          visibility: visibility,
        },
        {
          headers: { Authorization: "Bearer " + storeState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((res) => {
          console.log(res);
          findServices();
          clearForm();
          setOpenSnackbarS(true);
          setLoadingSave(false);
        })
        .catch((err) => {
          console.log(err);
          setOpenSnackbarF(true);
          setLoadingSave(false);
        });
    } else {
      console.log("faltan campos");
      setLoadingSave(false);
      swal("Warning!", t("Error, Please fill the Form"));
    }
  };

  const handleSaveEdit = (e) => {
    setLoadingSaveEdit(true);
    if (editName || editVisibility || editPrice || editDuration) {
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/services/update`,
        {
          id: edit,
          name: editName,
          provider_id: storeState[0].extendedData.id,
          description: editDescription,
          code: editCode,
          scope: "1",
          created_by: storeState[0].user.id,
          price: editPrice,
          duration_minute: editDuration,
          status: "ENABLED",
          visibility: editVisibility,
          appointment_type_id: "1", // TODO: Implement the rest of appointments types, here are only CONS
        },
        {
          headers: { Authorization: "Bearer " + storeState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((res) => {
          console.log(res);
          findServices();
          setEdit("");
          setCountClick(countClick + 1);
          setLoadingSaveEdit(false);
          setOpenSnackbarS(true);
        })
        .catch((err) => {
          console.log(err);
          setLoadingSaveEdit(false);
          setOpenSnackbarF(true);
        });
    } else {
      setLoadingSaveEdit(false);
    }
  };

  const handleDelete = (e) => {
    setLoadingSaveEdit(true);

    swal({
      title: t("Are you sure you want Delete this Service?"),
      text: t("Once accepted your service will be deleted"),
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then((ok) => {
      setLoadingSaveEdit(false);

      if (ok) {
        Axios.put(
          `${process.env.REACT_APP_API_URL_BASE}/services/update`,
          {
            id: edit,
            name: editName,
            provider_id: storeState[0].extendedData.id,
            description: editDescription,
            code: editCode,
            scope: "1",
            created_by: storeState[0].user.id,
            price: editPrice,
            duration_minute: editDuration,
            status: "DISABLED",
            visibility: editVisibility,
            appointment_type_id: "1",
          },
          {
            headers: { Authorization: "Bearer " + storeState[0].jwt }, //the jwt is a variable which holds the token
          }
        )
          .then((res) => {
            console.log(res);
            findServices();
            setEdit("");
            setCountClick(countClick + 1);
            setLoadingSaveEdit(false);
            setOpenSnackbarD(true);
          })
          .catch((err) => {
            console.log(err);
            setLoadingSaveEdit(false);
          });
      }
    });
  };

  function handleCode() {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    result += "_" + storeState[0].user.id;
    setCode(result);
  }

  useEffect(() => {
    findServices();
    handleCode();
  }, []);

  const handleServiceDuration = (value) => {
    setServiceDuration(value);
  };
  const handleEditServiceDuration = (value) => {
    setEditDuration(value);
  };

  const handleEdit = (id, service) => {
    console.log(service);
    setEdit(id);
    setEditDuration(service.duration_minute);
    setEditName(service.name);
    setEditDescription(service.description);
    setEditPrice(service.price);
    setEditCode(service.code);
    handleCountClick();
  };

  const handleCountClick = (e) => {
    setCountClick(countClick + 1);
    if (countClick % 2 === 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleCloseS = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarS(false);
  };

  const handleCloseF = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarF(false);
  };
  const handleCloseD = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarD(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <DashboardMUI
      sectionTitle={t("Service Settings")}
      description={t("Set all your services")}
      icon={providerIcon}
    >
      <div className="row">
        <div className="col-12">
          <div className="m-3 p-3 shadow rounded-lg-custom bg-white">
            <h4 className="title-dashboard-card pt-3 mb-0">
              {t("Add new Service")}
            </h4>
            <div className="row d-flex justify-content-center">
              <div className="col-12">
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  label={t("Service Title")}
                  variant="outlined"
                  id="name"
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-12">
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  label={t("Description")}
                  variant="outlined"
                  id="description"
                  value={description || ""}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-12">
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={price || ""}
                    placeholder={t("Price")}
                    onChange={(e) => setPrice(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    fullWidth
                  />
                </FormControl>
              </div>
              {/* Visibility of Service (Private or Public) */}
              <div className="col-md-12">
                <TextField
                  className="col-md-12 mt-3"
                  id="outlined-select-service-type"
                  select
                  label={t("Visibility")}
                  value={visibility}
                  onChange={(event, newValue) => {
                    setVisibility(event.target.value);
                  }}
                  // helperText="Please select new Service"
                  variant="outlined"
                >
                  <MenuItem value={"public"}>{t("Public")}</MenuItem>
                  <MenuItem value={"private"}>{t("Private")}</MenuItem>
                </TextField>
              </div>

              {/* End Scope of Service */}
              <div className="col-10 justify-content-center ">
                <Typography
                  className="justify-content-center mt-3"
                  id="discrete-slider"
                  gutterBottom
                >
                  {t("Duration in minutes")}
                </Typography>
                <Slider
                  defaultValue={15}
                  getAriaValueText={handleServiceDuration}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={15}
                  marks
                  min={15}
                  max={120}
                  onChange={(e) => setServiceDuration(e.target.value)}
                />
              </div>
              <div className="col-md-12 d-flex justify-content-center ">
                <div className="p-3">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={handleSave}
                  >
                    {t("Save")}
                  </Button>
                </div>
              </div>
              {loadingSave && (
                <div className="col-12 m-3">
                  <LoadingSpin type="spokes" color="#1d3557" />
                </div>
              )}
            </div>
            <h4 className="title-dashboard-card pt-3 mb-4">
              {t("Your Services")}
            </h4>
            {loading && (
              <div className="m-3">
                <LoadingSpin type="spokes" color="#1d3557" />
              </div>
            )}
            {services.map((service) =>
              service.status === "enabled" ? (
                <div className={classes.accordion}>
                  <Accordion key={service.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        {service.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="container-fluid p-0 row">
                        <div className="col-5 flex-column">
                          <Typography color="primary">{t("Price")}:</Typography>
                          <Typography color="primary">
                            {t("Duration")}:{" "}
                          </Typography>
                          <Typography color="primary">
                            {t("Visibility") + ":"}
                          </Typography>
                        </div>
                        <div className="col-5 flex-column">
                          <Typography>{"$ " + service.price}</Typography>
                          <Typography>
                            {service.duration_minute + " min"}
                          </Typography>
                          <Typography>{service.visibility}</Typography>
                        </div>
                        <div className="col-2">
                          <IconButton
                            onClick={(e) => {
                              handleEdit(service.id, service);
                            }}
                            color="primary"
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                        {edit === service.id && open === true ? (
                          <div className="row pl-3 mt-3 d-flex justify-content-center">
                            <div className="col-12">
                              <TextField
                                required
                                fullWidth
                                margin="normal"
                                label={t("Name")}
                                variant="outlined"
                                id="Name"
                                value={editName || ""}
                                onChange={(e) => setEditName(e.target.value)}
                              />
                            </div>
                            <div className="col-12">
                              <FormControl fullWidth variant="outlined">
                                <OutlinedInput
                                  id="outlined-adornment-amount"
                                  value={editPrice || ""}
                                  placeholder="Price"
                                  onChange={(e) => setEditPrice(e.target.value)}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  }
                                  fullWidth
                                />
                              </FormControl>
                            </div>
                            <div className="col-md-12">
                              <TextField
                                className="col-md-12 mt-3"
                                id="outlined-select-service-type"
                                select
                                label={t("Visibility")}
                                value={editVisibility || "public"}
                                onChange={(event, newValue) => {
                                  setEditVisibility(event.target.value);
                                }}
                                // helperText="Please select new Service"
                                variant="outlined"
                              >
                                <option value={"public"} name="Public">
                                  {t("Public")}
                                </option>
                                <option value={"private"} name="Private">
                                  {t("Private")}
                                </option>
                              </TextField>
                            </div>
                            <div className="col-10 justify-content-center ">
                              <Typography
                                className="justify-content-center mt-3"
                                id="discrete-slider"
                                gutterBottom
                              >
                                {t("Duration in minutes")}
                              </Typography>
                              <Slider
                                defaultValue={editDuration}
                                getAriaValueText={handleEditServiceDuration}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={15}
                                marks
                                min={15}
                                max={120}
                                onChange={(e) =>
                                  setEditDuration(e.target.value)
                                }
                              />
                            </div>
                            {loadingSaveEdit && (
                              <div className="col-12 m-3">
                                <LoadingSpin type="spokes" color="#1d3557" />
                              </div>
                            )}
                            <div className="col-md-12 d-flex justify-content-center ">
                              <div className="p-3">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  startIcon={<CheckCircleOutlineIcon />}
                                  onClick={handleSaveEdit}
                                >
                                  {t("Save")}
                                </Button>
                              </div>
                              <div className="p-3">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  startIcon={<DeleteIcon />}
                                  onClick={handleDelete}
                                >
                                  {t("Delete")}
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={openSnackbarS}
        autoHideDuration={3000}
        onClose={handleCloseS}
      >
        <Alert onClose={handleCloseS} severity="success">
          {t("Saved Success!")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarF}
        autoHideDuration={3000}
        onClose={handleCloseF}
      >
        <Alert onClose={handleCloseF} severity="error">
          {t("Something went wrong, please contact the Admin ")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarD}
        autoHideDuration={3000}
        onClose={handleCloseD}
      >
        <Alert onClose={handleCloseD} severity="error">
          {t("Service Deleted!")}
        </Alert>
      </Snackbar>
    </DashboardMUI>
  );
}
