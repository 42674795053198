import React from "react";
import { FormControlLabel, Switch, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    width: 500,
  },
  label: {
    marginRight: theme.spacing(1),
  },
}));

export default function EHRVersionToggle({ useNewVersion, onToggle, t }) {
  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.root}>
      <Typography variant="body1" className={classes.label}>
        {t("EHR Version")}
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={useNewVersion}
            onChange={(e) => onToggle(e.target.checked)}
            color="primary"
            name="ehrVersion"
          />
        }
        label={useNewVersion ? t("New Version") : t("Classic Version")}
      />
    </Paper>
  );
}
