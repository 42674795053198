import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function PlannerMenuTab({
  calendarSelection,
  setCalendarSelection,
  calendarsAvailable,
}) {
  const classes = useStyles();
  // Initialize with the first calendar's ID or 1 if calendarsAvailable exists
  const [value, setValue] = useState(calendarsAvailable?.[0]?.id || 1);
  const calendars = calendarsAvailable || [{ id: 1, name: "Virtual" }];

  useEffect(() => {
    // Set initial calendar selection
    setCalendarSelection(value);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCalendarSelection(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {calendars.map((calendar) => (
          <Tab
            label={calendar?.name || "Virtual"}
            key={calendar?.id}
            value={calendar?.id}
          />
        ))}
      </Tabs>
    </Paper>
  );
}
