import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./home";
import PatientsRegister from "./patients/PatientsRegister";
import ProviderRegister from "./providers/ProviderRegister";
import PatientsList from "./patients/PatientsList";
import Roles from "./security/Roles";
import Users from "./security/Users";
import RoleEdit from "./security/RoleEdit";
import PatientsRegistration from "./registration/PatientsRegistration";
import LoginFront from "./login/LoginFront";
import MainPatient from "./patient-portal/MainPatient";
import MainProviders from "./provider-portal/MainProviders";
import LoginMain from "./login-providers/LoginMain";
import ProvidersRegistration from "./registration/ProvidersRegistration";
import ProvidersList from "./providers/ProvidersList";
import MainProviderVerification from "./provider-portal/MainProviderVerification";
import PatientEdit from "./patients/PatientEdit";
import MyCalendar from "./providers/MyCalendar";
import PatientAppointment from "./patients/PatientAppointment";
import ForgotPassword from "./providers/ForgotPassword";
import VerificationStep from "./patients/VerificationStep";
import ProviderRecoverPassword from "./providers/ProviderRecoverPassword";
import AppointmentsList from "./patient-portal/AppointmentsList";
import PatientForgotPassword from "./patients/ForgotPassword";
import PatientRecoverPassword from "./patients/PatientRecoverPassword";
import PatientEditProfile from "./patients/PatientEditProfile";
import ProviderEditProfile from "./providers/ProviderEditProfile";
import LobbyPatient from "./patient-portal/LobbyPatient";
import NotFound from "./utils/NotFound";
import LobbyProvider from "./provider-portal/LobbyProvider";
import HealthCareProviders from "./patient-portal/HealthCareProviders";
import Checkout from "./payments/Checkout";
import ProviderServiceSettings from "./providers/ProviderServiceSettings";
import ProviderUserSettings from "./providers/ProviderUserSettings";
import ProviderSetting from "./providers/ProviderSetting";
import VerificationStepProv from "./providers/VerificationStepProv";
import ProviderDetailsFromPatient from "./providers/ProviderDetailsFromPatient";
import ProviderEditFromAdmin from "./providers/ProviderEditFromAdmin";
import Planner from "./planner/Planner";
import TextConsultation from "./patients/TextConsultation";
import ProviderTextConsult from "./providers/ProviderTextConsult";
import InboxPatient from "./patients/InboxPatient";
import UserEdition from "./security/administration/UserEdition";
import ProviderAppointment from "./providers/ProviderAppointment";
import LoginAdministrator from "./administrator/login/LoginAdministrator";
import MainInsights from "./administrator/main/MainInsights";
import UserCreation from "./administrator/registration/UserCreation";
import PatientsAdminList from "./patients/PatientsAdminList";
import OrdersSummary from "./patient-portal/OrdersSummary";
import Flags from "./flags";
import Rating from "./rating/Rating";
import Login from "./login/Login";
import MainOperations from "./administrator/main/MainOperations";
import OperationsAppointment from "./administrator/appointments/OperationsAppointment";
import ProviderDetailsOP from "./administrator/appointments/ProviderDetailsOP";
import AppointmentHistoryOP from "./administrator/appointments/AppointmentsHistoryOP";
import PharmacyForm from "./administrator/prescriptions/PharmacyForm";
import CheckoutResult from "./payments/CheckoutResult";
import ManagePatientProfile from "./administrator/users/ManagePatientProfile";
import AdminSetting from "./administrator/settings/AdminSetting";
import PlanWizard from "./administrator/settings/plans/PlanWizard";
import PaymentCheckoutForm from "./registration/PaymentCheckOutForm";
import IncludeFamilyMemberForm from "./registration/IncludeFamilyMemberForm";
import PaymentShowPlans from "./registration/HealthPlans";
import HealthPlans from "./registration/HealthPlans";
import NoPlans from "./NoPlansCard";
import AffiliationsPlan from "./administrator/settings/affiliations/AffiliationsPlan";
import SoleVideoFrame from "./videoconference/SoleVideoFrameV2";
import ReportList from "./administrator/reports/ReportList";
import ApptReport from "./administrator/reports/appointments/ApptReport";
import GreenpayCheckoutCallback from "./payments/GreenpayCheckoutCallback";
import ReportsProvider from "./providers/ReportsProvider";
import PatientsReport from "./administrator/reports/appointments/PatientsReport";
import PatientsReportCie10 from "./administrator/reports/appointments/PatientsReportCie10";
import PatientsReportPlans from "./administrator/reports/appointments/PatientsReportPlans";
import PracticeLocationManagement from "./administrator/settings/PracticeLocationManagement";
import OfflinePaymentsReport from "./administrator/reports/OfflinePaymentsReport";
import AppointmentsIncomeReport from "./providers/reports/AppointmentsIncomeReport";
import ReportsSelection from "./administrator/reports/ReportsSelection"; // Import the new component
import PatientStatsReport from "./administrator/reports/appointments/PatientStatsReport";
import AppointmentDetailsReport from "./administrator/reports/appointments/AppointmentDetailsReport";
import ConfigurationManager from "./administrator/settings/ConfigurationManager";
import PendingBeneficiaries from "./administrator/settings/beneficiaries/PendingBeneficiaries";

export default function HomeRouter() {
  // const [userData, setUserData] = useState("");
  // const [userProfileImg, setUserProfileImg] = useState({
  //   profileImg: "",
  //   exists: null
  // });
  // const [newUserProfileImg, setNewUserProfileImg] = useState("");
  // const changeUserData = (data) => setUserData(data);
  // const changeUserProfileImg = (data) => setUserProfileImg(data);
  // const changeNewUserProfileImg = (data) => setNewUserProfileImg(data);

  return (
    <Router>
      <Switch>
        <Route exact path="/quicklinks">
          <Login />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <h1>From About Route</h1>
        </Route>
        {/* Sole VideoFrame for un-authenticated users */}
        <Route exact path="/videoconference/:id/:type">
          <SoleVideoFrame />
        </Route>

        <Route exact path="/patient/login">
          <Login />
        </Route>
        <Route exact path="/patient/login/recovery">
          <PatientForgotPassword />
        </Route>
        <Route exact path="/patient/login/recover/:id">
          <PatientRecoverPassword />
        </Route>
        <Route exact path="/patient/editProfile">
          <PatientEditProfile />
        </Route>
        {/* Lobby for Virtual Consultation for Patient */}
        <Route exact path="/patient/lobby">
          <LobbyPatient />
        </Route>
        <Route exact path="/patient/appointments">
          <AppointmentsList />
        </Route>
        <Route exact path="/patient/appointments/create">
          <PatientAppointment />
        </Route>
        <Route exact path="/patient/registration">
          <PatientsRegistration />
        </Route>
        <Route exact path="/patient/beneficiary/success">
          <IncludeFamilyMemberForm />
        </Route>
        <Route exact path="/patient/registration/healthPlans">
          <HealthPlans />
        </Route>
        <Route exact path="/patient/registration/payPlant">
          <PaymentCheckoutForm />
        </Route>
        {/* Route for confirm the patient account sended by email, the :id is the Token */}
        <Route exact path="/patient/confirm/:id">
          <VerificationStep />
        </Route>
        <Route exact path="/patient/dashboard">
          <MainPatient />
        </Route>
        <Route exact path="/patient/textconsultation">
          <TextConsultation type="pat" />
        </Route>
        <Route exact path="/provider/textconsultation">
          <TextConsultation type="pro" />
        </Route>
        {/* ROute to access the EHC from Patient Section */}
        <Route exact path="/patient/ehc">
          <MainPatient />
        </Route>

        <Route exact path="/patient/orders">
          <OrdersSummary />
        </Route>

        {/* Inbox patient */}
        <Route exact path="/patient/inbox">
          <InboxPatient />
        </Route>
        <Route exact path="/patient/providers">
          <HealthCareProviders />
        </Route>
        <Route exact path="/admin/login">
          <LoginMain />
        </Route>
        <Route exact path="/admin/dashboard">
          <MainProviders />
        </Route>
        {/* Route Setting for Providers and Admin */}
        <Route exact path="/admin/settings">
          <ProviderSetting />
        </Route>
        <Route exact path="/admin/serviceSettings">
          <ProviderServiceSettings />
        </Route>
        <Route exact path="/admin/userSettings">
          <ProviderUserSettings />
        </Route>
        <Route exact path="/admin/patient/list">
          <PatientsList />
        </Route>
        {/* Route for Edit a Patient */}
        <Route exact path="/admin/patient/edit/:id?">
          <PatientEdit />
        </Route>
        <Route exact path="/admin/calendar">
          <MyCalendar />
        </Route>
        <Route exact path="/admin/newAppointment">
          <ProviderAppointment />
        </Route>
        <Route exact path="/admin/login/recovery">
          <ForgotPassword />
        </Route>
        <Route exact path="/user/login/recover/:id">
          <ProviderRecoverPassword />
        </Route>

        <Route exact path="/provider/editProfile">
          <ProviderUserSettings />
        </Route>
        <Route path="/patient/provider/details/:id">
          <ProviderDetailsFromPatient />
        </Route>

        <Route exact path="/admin/textconsult">
          <ProviderTextConsult />
        </Route>

        <Route exact path="/admin/patientRegister">
          <PatientsRegister />
        </Route>

        {/* Lobby for Virtual Consultation for Patient */}
        <Route exact path="/admin/provider/lobby">
          <LobbyProvider />
        </Route>
        {/* Route for Provider Registration from Front */}
        <Route exact path="/admin/provider/registration">
          <ProvidersRegistration />
        </Route>
        {/* Route for account confirmation for PROVIDERS */}
        <Route exact path="/admin/provider/confirm/:id">
          <MainProviderVerification />
        </Route>
        {/* Route for Appointment Checkout - Patient */}
        <Route exact path="/patient/checkout/appointment">
          <Checkout />
        </Route>
        {/* checkout route with payment_intent parameter */}
        <Route exact path="/patient/checkout/result">
          <CheckoutResult />
        </Route>
        {/* GreenPay Callback Webform route */}
        <Route exact path="/patient/checkout/gp/result/:token">
          <GreenpayCheckoutCallback />
        </Route>

        <Route exact path="/admin/provider/planner">
          <Planner />
        </Route>

        <Route exact path="/admin/reports">
          <ReportsSelection /> {/* Use the new ReportsSelection component */}
        </Route>

        <Route exact path="/admin/report/appointments/income">
          <AppointmentsIncomeReport />
        </Route>

        <Route exact path="/admin/report/patients">
          <PatientStatsReport />
        </Route>

        <Route exact path="/admin/report/appointment">
          <AppointmentDetailsReport />
        </Route>

        {/* Rate Appointment for Patient */}
        <Route exact path="/patient/rate/appointment/">
          <Rating />
        </Route>
        <Route exact path="/patient/rate/appointment/:id">
          <Rating />
        </Route>

        {/*  ROUTES FOR ADMIN && SPECIAL USERS PORTAL  */}
        <Route exact path="/users/login">
          <LoginAdministrator />
        </Route>
        <Route exact path="/users/home">
          <MainInsights />
        </Route>
        <Route exact path="/users/op/home">
          <MainOperations />
        </Route>

        <Route exact path="/users/provider/list">
          <ProvidersList />
        </Route>

        <Route exact path="/users/op/appointments/create">
          <OperationsAppointment />
        </Route>

        <Route path="/users/op/provider/details/:id">
          <ProviderDetailsOP />
        </Route>

        <Route path="/users/op/places">
          <PharmacyForm />
        </Route>

        <Route exact path="/users/settings">
          <AdminSetting />
        </Route>

        <Route exact path="/users/plan/create">
          <PlanWizard />
        </Route>

        <Route exact path="/users/affiliations/list">
          <AffiliationsPlan />
        </Route>

        <Route exact path="/users/report/list">
          <ReportList />
        </Route>

        <Route exact path="/users/report/appointment">
          <ApptReport />
        </Route>

        <Route exact path="/users/report/patients">
          <PatientsReport />
        </Route>
        <Route exact path="/users/report/patientsCie10">
          <PatientsReportCie10 />
        </Route>
        {/* <Route exact path="/users/report/patients/plans">
          <PatientsReportPlans />
        </Route> */}

        {/* Route for Edit a Provider */}
        <Route exact path="/users/provider/edit/:id?">
          <ProviderEditFromAdmin />
        </Route>
        <Route exact path="/users/providerRegister">
          <ProviderRegister />
        </Route>
        <Route exact path="/users/patient/list">
          <PatientsAdminList />
        </Route>
        <Route exact path="/users/users">
          <Users />
        </Route>
        <Route exact path="/users/role">
          <Roles />
        </Route>

        <Route exact path="/users/op/appt/patient/:id?">
          <AppointmentHistoryOP />
        </Route>

        <Route exact path="/users/role/edit/:id?">
          <RoleEdit />
        </Route>
        <Route exact path="/users/create">
          <UserCreation />
        </Route>
        <Route exact path="/users/edit/:id?">
          <UserEdition />
        </Route>

        <Route exact path="/users/practice-locations">
          <PracticeLocationManagement />
        </Route>

        <Route exact path="/users/patient/edit/:id?">
          <ManagePatientProfile />
        </Route>

        <Route exact path="/users/report/offlinePayments">
          <OfflinePaymentsReport />
        </Route>

        <Route exact path="/users/pending-beneficiaries">
          <PendingBeneficiaries />
        </Route>

        <Route exact path="/users/settings/configurations">
          <ConfigurationManager />
        </Route>

        {/* Route for 404 NotFound */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}
