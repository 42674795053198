import React, { useState, useEffect, useMemo } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import PatientProfile from "./PatientProfile";
import "../../styles/customs.css";
import swal from "sweetalert";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import MuiAlert from "@material-ui/lab/Alert";
import DashboardPatient from "../dashboard/DashboardPatient";
//Redux
import { setUserAction, setUserImageProfile } from "../../actions";
import { setNewUserImageProfile } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import UseUserState from "../hooks/useUserState";
/** Matetial UI New Imports */
import {
  Snackbar,
  TextField,
  MenuItem,
  Button,
  Grid,
  Link,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TabScrollablePatient from "./TabScrollablePatient";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { useAffiliationsBeneficiaries } from "../../services/services";
import NewEHRVersion from "./NewEHRVersion";
import EHRVersionToggle from "./EHRVersionToggle";
import useCalcBmi from "../hooks/useCalcBmi";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function PatientEditProfile(props) {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [identification, setIdentification] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(true);
  const [country, setCountry] = useState("");
  const [nationality, setNationality] = useState("");
  const [patientId, setPatientId] = useState("");
  const [userImageURL, setUserImageURL] = useState("");
  const [imgProfile, setImgProfile] = useState({
    profile: "",
    exist: null,
  });
  const [countries, setCountries] = useState([]);
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState("");
  const [bgPersonal, setBgPersonal] = useState([]);
  const [bgFamily, setBgFamily] = useState([]);

  const [data, setData] = useState("");
  const [openAlertImg, setOpenAlertImg] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  const [open, setOpen] = useState(false);

  //Distpach of Redux
  const distpach = useDispatch();

  // Hooks that retrieve the user data from LocalStorage
  const userState = UseUserState();

  const newUserImageState = useSelector((state) => state.newUserImage);

  // For EHC Section
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [consultationNotes, setConsultationNotes] = useState([]);
  const [healthConditions, setHealthConditions] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [healthMeasurement, setHealthMeasurement] = useState([]);
  const [dependants, setDependants] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const { t } = useBDocTranslator();
  const [appointmentFiles, setAppointmentFiles] = useState([]);
  const [healthPlan, setHealthPlan] = useState("");
  const [healthPlanQuestion, setHealthPlanQuestion] = useState("NO");

  const { affiliateBeneficiarie } = useAffiliationsBeneficiaries(
    userState[0].jwt
  );

  const [useNewVersion, setUseNewVersion] = useState(false);

  const { calcBmi } = useCalcBmi();

  const handleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  const clearForm = () => {
    document.getElementById("patient-form").reset();
    setDateOfBirth("");
    setEmail("");
    setGender("");
    setLastname("");
    setfirstName("");
    setPhone("");
  };

  // ----------------- COUNTRIES ----------------------

  const findCountries = () => {
    // if(email != ""){
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/countries/findAll`)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // -------------------------- ALERT -----------------------------

  const handleAlertImg = () => {
    setOpenAlertImg(true);
  };

  const handleCloseAlertImg = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertImg(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // -------------------------- IMAGE UPDATE CLOUDINARY --------------------

  const handleSaveProfileImg = (imageUrl) => {
    if (imageUrl) {
      setOpen(true);

      const reader = new FileReader();
      reader.readAsDataURL(imageUrl);

      reader.onload = async () => {
        const dataUrl = reader.result;
        const response = await Axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL_BASE}/userImage/save`,
          data: {
            file_name: `${firstName}ProfileImg`,
            created_by_user: userState[0].user.id,
            file_profile: dataUrl,
          },
          headers: {
            Authorization: "Bearer " + userState[0].jwt,
            // "Content-Type": "multipart/form-data",
          },
        });

        setImgLoading(false);
        handleAlertImg();
        const imgProfile = setUserImageProfile({
          profileImg: response.data.path_resource,
          exists: true,
        });
        distpach(imgProfile);
        findImgProfile();
        setOpen(false);
        swal("Success!", "Image profile saved successfully", "success");
      };
    } else {
      console.log("no hay imagen seleccionada");
    }
  };

  // ----------------------- PROFILE UPDATE ------------------------- //
  const handleClick = (e) => {
    e.preventDefault();
    if (healthPlanQuestion === "YES" && healthPlan === "") {
      setErrorMessage({
        error: "Error, Please specify your Health Plan",
        visible: true,
        msgClass: "alert alert-danger ",
      });
      return;
    }
    if (
      email &&
      phone &&
      firstName &&
      lastname &&
      dateOfBirth &&
      gender &&
      patientId &&
      identification &&
      country
    ) {
      setLoading(true);
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/userPatient/updateAndConfirm`,
        {
          phone1: phone,
          firstName,
          lastname,
          dateOfBirth,
          gender,
          identificationType,
          identification,
          country,
          nationality,
          id: userState[0].extendedData.id,
          insurance_policy_number: insurancePolicyNumber,
          insurance_provider: insuranceProvider,
          health_plan: healthPlan,
        },
        {
          headers: { Authorization: "Bearer " + userState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoading(false);
          swal({
            tittle: "Success!",
            text: t("Changes Saved"),
            icon: "success",
            button: false,
            timer: 3000,
          });
          // DELETE COMPLETE INFO
          if (!!userState[0].user.completeInfo) {
            const newUser = userState[0];
            delete newUser.user.completeInfo;
            distpach(setUserAction(newUser));
          }

          setErrorMessage({
            error: "",
            visible: false,
            msgClass: "",
          });
          //clearForm();
          //handleSaveProfileImg();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setErrorMessage({
            error: error,
            visible: true,
            msgClass: "alert alert-danger d-flex justify-content-center",
          });
        });
    } else {
      setErrorMessage({
        error: "Error, Please fill the Form",
        visible: true,
        msgClass: "alert alert-danger ",
      });
    }
  };

  // -------------------- USEEFFECT ----------------------------

  // Request Prescriptions related to Patient Selected
  async function fetchPrescription(idPatient) {
    //Then Requesting Prescriptions
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/prenscriptions/findByPatient?patientId=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setPrescriptionData(res.data);
      })
      .catch((err) => console.log(err));
  }

  // Request consultationNotes related to Patient
  async function fetchConsultationNotes(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/consultations/patient/findAll?patientId=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setConsultationNotes(res.data);
      })
      .catch((err) => console.log(err));
  }

  // Request Health Conditions
  async function fetchHealthConditions(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/healthconditions/findByPatient?patient_id=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setHealthConditions(res.data);
      })
      .catch((err) => console.log(err));
  }

  // Request Health Measurement
  async function fetchHealthMeasurements(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/measurement/findByPatient?patient_id=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setHealthMeasurement(res.data);
      })
      .catch((err) => console.log(err));
  }

  async function fetchDependants(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/patient/findDependant?patientId=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setDependants(res.data);
      })
      .catch((err) => console.log(err));
  }

  async function fetchVaccines(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/ehr/findByPatientId?patient_id=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setVaccines(res.data);
      })
      .catch((err) => console.log(err));
  }

  async function fetchAppointmentFiles(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/appointments/files?patient_id=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setAppointmentFiles(res.data);
      })
      .catch((err) => console.log(err));
  }

  async function fetchBackgrounds(idPatient) {
    await Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/bg/all?patient=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setBgFamily(res.data?.family || []);
        setBgPersonal(res.data?.personal || []);
      })
      .catch((err) => console.log(err));
  }

  //Functions Calls
  async function fetchData(patientinfo) {
    await fetchPrescription(patientinfo.id);
    await fetchConsultationNotes(patientinfo.id);
    await fetchHealthConditions(patientinfo.id);
    await fetchHealthMeasurements(patientinfo.id);
    await fetchDependants(patientinfo.id);
    await fetchVaccines(patientinfo.id);
    await fetchAppointmentFiles(patientinfo.id);
    await fetchBackgrounds(patientinfo.id);
  }

  const recall = {
    fetchPrescription,
    fetchConsultationNotes,
    fetchHealthConditions,
    fetchHealthMeasurements,
    fetchDependants,
    fetchVaccines,
    fetchAppointmentFiles,
    fetchBackgrounds,
  };

  useEffect(() => {
    console.log("PatId", userState[0].extendedData);
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/patient/findById/${userState[0].extendedData?.id}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        setLoading(false);
        //Set Data to Controls
        setPatientData(res.data);
        fetchData(res.data);
        findCountries();
        loadDataToControls(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [refreshData]);

  // ------------------ FIND IMAGE CLOUDINARY ------------------
  const findImgProfile = () => {
    // if(email != ""){
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/userImage/findByUserId`, {
      headers: {
        Authorization: "Bearer " + userState[0].jwt,
        "Content-Type": "application/json",
      },
      params: { createdBy: userState[0].user.id },
    })
      .then((res) => {
        setImgLoading(false);
        distpach(
          setUserImageProfile({
            profileImg: res.data.path_resource,
            exists: true,
          })
        );
        distpach(setNewUserImageProfile(""));
      })
      .catch((err) => {
        console.log(err);
        setImgLoading(false);
        distpach(setUserImageProfile({ profileImg: "", exists: false }));
      });
  };

  // ----------------------  FUNC SET STATES --------------------

  function loadDataToControls(data) {
    if (data) {
      const {
        user: { email, user_images },
        firstName,
        lastname,
        identification,
        dateOfBirth,
        gender,
        country,
        nationality,
        id,
        phone1,
        identificationType,
        insurance_policy_number,
        insurance_provider,
        health_plan,
        affiliations,
        plan_name,
      } = data;
      setEmail(email);
      setfirstName(firstName);
      setLastname(lastname);
      setIdentification(identification);
      setDateOfBirth(dateOfBirth && dateOfBirth.split("T")[0]);
      setGender(gender);
      setCountry(country);
      setNationality(nationality);
      setPatientId(id);
      setPhone(phone1);
      setIdentificationType(identificationType);
      setInsurancePolicyNumber(insurance_policy_number);
      setInsuranceProvider(insurance_provider);
      setHealthPlanQuestion(() => {
        if (
          affiliations.some((affiliation) => affiliation.status === "active") ||
          plan_name
        ) {
          return "YES";
        }
        return "NO";
      });
      setUserImageURL(() => {
        if (user_images.length > 0) {
          return user_images.at(-1).path_resource;
        }
        return "";
      });
      setHealthPlan(() => {
        return (
          affiliations.find((affiliation) => affiliation.status === "active")
            ?.plan_name ||
          plan_name ||
          "PLAN NOT FOUND"
        );
      });
    }
  }

  // Load Countries from Database
  // useEffect(() => {
  //   // findCountries();
  //   //findImgProfile();
  // }, []);

  //this method manage the backdrop status
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteCondition = (id) => {
    if (id) {
      Axios.delete(
        `${process.env.REACT_APP_API_URL_BASE}/healthconditions/delete/${id}`,
        {
          headers: {
            Authorization: "Bearer " + userState[0].jwt,
          },
        }
      )
        .then((res) => {
          swal({
            tittle: "Success!",
            text: t("Condition Deleted"),
            icon: "success",
            button: false,
            timer: 3000,
          });
          handleRefreshData();
        })
        .catch((err) => console.log(err));
    }
  };

  // method that receive the status (active, inactive) beneficiary data from the click made in a swith button in the DependantsPatient component, and send the request to the backend to update the beneficiary status
  const handleIncludeBeneficiary = async (
    beneficiary,
    status,
    affiliationId
  ) => {
    try {
      if (beneficiary && status) {
        const affiliationData = [
          {
            affiliation_id: affiliationId,
            patient_id: beneficiary?.id,
            status: status,
          },
        ];

        // call the affiliation endpoint
        const result = await affiliateBeneficiarie(affiliationData);
        if (result.message.includes("reached")) {
          swal(
            "Error!",
            `Familiar Registrado con Error:  ${t(result?.message)}`,
            "error"
          );
          return;
        }
        if (result?.message) swal("Success!", "Operacion exitosa", "success");
      }
      handleRefreshData();
    } catch (e) {
      console.log(e);
    }
  };

  // Define calcBodyMass function
  const calcBodyMass = useMemo(() => {
    const measurementsSorted = healthMeasurement.sort(function (a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    const latestSize = measurementsSorted.find(
      (i) => i.measurement_type === "SIZ"
    );
    const latestWeight = measurementsSorted.find(
      (i) => i.measurement_type === "WEI"
    );

    if (!latestWeight && !latestSize) {
      return {
        error: true,
        message: "Please add Weight and Size to calculate Body Mass Index",
      };
    }
    if (!latestWeight && !!latestSize) {
      return {
        error: true,
        message: "Please add Weight to calculate Body Mass Index",
      };
    }
    if (!!latestWeight && !latestSize) {
      return {
        error: true,
        message: "Please add Size to calculate Body Mass Index",
      };
    }
    if (latestSize && latestWeight) {
      const bmiResult = calcBmi(latestSize.result, latestWeight.result);
      return {
        latestSize,
        latestWeight,
        data: bmiResult,
      };
    }
    return {
      error: true,
      message: "Please add Weight and Size to calculate Bosy Mass Index",
    };
  }, [healthMeasurement]);

  return (
    <>
      {
        <DashboardPatient>
          <Backdrop
            className={classes.backdrop}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="shadow-lg p-3 mb-5 bg-white rounded w-vw-100">
            <div className="row d-flex justify-content-center">
              <div className="col-md-4 d-flex justify-content-center">
                <PatientProfile
                  email={email}
                  imgLoading={imgLoading}
                  userImageURL={userImageURL}
                  handleSaveProfileImg={handleSaveProfileImg}
                  t={t}
                />
              </div>
              <div className="col-md-9">
                <form className="row" id="patient-form">
                  <div className="form-group col-md-6">
                    <TextField
                      margin="normal"
                      label={t("First Name")}
                      variant="outlined"
                      id="firstName"
                      fullWidth
                      onChange={(e) => setfirstName(e.target.value)}
                      required
                      value={firstName || ""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label={t("Last Name")}
                      variant="outlined"
                      id="lastname"
                      onChange={(e) => setLastname(e.target.value)}
                      value={lastname || ""}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      required
                      margin="normal"
                      label={t("Your Email")}
                      variant="outlined"
                      id="email"
                      value={email || ""}
                      autoComplete="email"
                      fullWidth
                      disabled={true}
                      placeholder="name@example.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-8">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label={t("Phone Number")}
                      variant="outlined"
                      id="phoneNumber"
                      value={phone || ""}
                      placeholder="888823456"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-4 ">
                    <TextField
                      id="country"
                      required
                      select
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      placeholder="Select"
                      label={t("Country")}
                      value={country || "select"}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      {countries &&
                        countries.map((country) => {
                          return (
                            <MenuItem key={country.id} value={country.code}>
                              {country.name}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </div>
                  <div className="form-group col-md-3">
                    <TextField
                      id="identificationType"
                      required
                      select
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      placeholder="Select"
                      label={t("Identification Type")}
                      onChange={(e) => setIdentificationType(e.target.value)}
                      value={identificationType || "select"}
                    >
                      <MenuItem value="DNI">{t("DNI")}</MenuItem>
                      <MenuItem value="PASSPORT">{t("Passport")}</MenuItem>
                      <MenuItem value="CED">
                        {t("Cedula de Identidad")}
                      </MenuItem>

                      <MenuItem value="other">{t("Other")}</MenuItem>
                    </TextField>
                  </div>

                  <div className="form-group col-md-9">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label={t("Identification number")}
                      variant="outlined"
                      id="identification"
                      onChange={(e) => setIdentification(e.target.value)}
                      value={identification || ""}
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      label={t("Date Of Birth")}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      value={dateOfBirth || ""}
                    />
                  </div>
                  {/* Gender */}
                  <div className="form-group col-md-8">
                    <TextField
                      id="gender"
                      required
                      select
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      placeholder="Select"
                      label={t("Gender")}
                      value={gender || "select"}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <MenuItem value={""}>{t("None")}</MenuItem>
                      <MenuItem value={"MALE"}>{t("Male")}</MenuItem>
                      <MenuItem value={"FEMALE"}>{t("Female")}</MenuItem>
                      <MenuItem value={"OTHER"}>{t("Other")}</MenuItem>
                    </TextField>
                  </div>

                  {/* Radio button to allow the user select if it has Health Plan or not, if yes, hide the Insurance fields and show a TextField of type select with the list of existing Health Plans */}
                  <div className="form-group col-md-12">
                    <TextField
                      id="healthPlanQuestion"
                      select
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      placeholder="Select"
                      label={t("Do you have a Health Plan?")}
                      value={healthPlanQuestion || "Select"}
                      onChange={(e) => setHealthPlanQuestion(e.target.value)}
                      disabled={true}
                    >
                      <MenuItem value={""}>{t("Select")}</MenuItem>
                      <MenuItem value={"YES"}>{t("Yes")}</MenuItem>
                      <MenuItem value={"NO"}>{t("No")}</MenuItem>
                    </TextField>
                  </div>

                  {healthPlanQuestion === "YES" ? (
                    <div className="form-group col-md-12">
                      <TextField
                        fullWidth
                        margin="normal"
                        label={t("Healthcare Plan")}
                        variant="outlined"
                        id="healthcare-plan"
                        onChange={(e) => setHealthPlan(e.target.value)}
                        value={healthPlan || ""}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <>
                      {/* Insurance Information */}
                      <div className="form-group col-md-4">
                        <TextField
                          fullWidth
                          margin="normal"
                          label={t("Insurance Company")}
                          variant="outlined"
                          id="insurance-provider"
                          onChange={(e) => setInsuranceProvider(e.target.value)}
                          value={insuranceProvider || ""}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <TextField
                          fullWidth
                          margin="normal"
                          label={t("Insurance Policy Number")}
                          variant="outlined"
                          id="insurance-policy-number"
                          onChange={(e) =>
                            setInsurancePolicyNumber(e.target.value)
                          }
                          value={insurancePolicyNumber || ""}
                        />
                      </div>
                      {/* End Insurance Information */}
                    </>
                  )}

                  <Grid container justify="flex-start" className="m-3">
                    <Grid item>
                      <Link
                        href={"/patient/login/recover/" + userState[0].jwt}
                        variant="body2"
                      >
                        {t("Change Password")}
                      </Link>
                    </Grid>
                  </Grid>
                  <div className="col-md-12 mt-3 d-flex justify-content-center ">
                    {errorMessage.visible && (
                      <div className={errorMessage.msgClass} role="alert">
                        {t(errorMessage.error)}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center ">
              <div className="p-3">
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<CancelIcon />}
                  onClick={(e) => history.goBack()}
                >
                  {t("Cancel")}
                </Button>
              </div>
              <div className="p-3">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CheckCircleOutlineIcon />}
                  onClick={handleClick}
                >
                  {t("Save")}
                </Button>
              </div>
            </div>
          </div>
          <div>
            <div className="container-fluid pt-5 w-vw-100">
              <EHRVersionToggle
                useNewVersion={useNewVersion}
                onToggle={setUseNewVersion}
                t={t}
              />

              {useNewVersion ? (
                <NewEHRVersion
                  prescription={prescriptionData}
                  consultationNotes={consultationNotes}
                  healthConditions={healthConditions}
                  patientId={patientId}
                  handleRefreshData={handleRefreshData}
                  healthMeasurement={healthMeasurement}
                  dependants={dependants}
                  vaccines={vaccines}
                  appointmentFiles={appointmentFiles}
                  handleDeleteCondition={handleDeleteCondition}
                  recall={recall}
                  bgFamily={bgFamily}
                  bgPersonal={bgPersonal}
                  affiliationId={
                    patientData?.affiliations?.find(
                      (affiliation) => affiliation.status === "active"
                    )?.id
                  }
                  handleIncludeBeneficiary={handleIncludeBeneficiary}
                  calcBodyMass={calcBodyMass}
                  t={t}
                />
              ) : (
                <TabScrollablePatient
                  prescription={prescriptionData}
                  consultationNotes={consultationNotes}
                  patientEmail={email}
                  healthConditions={healthConditions}
                  patientId={patientId}
                  handleRefreshData={handleRefreshData}
                  healthMeasurement={healthMeasurement}
                  dependants={dependants}
                  vaccines={vaccines}
                  appointmentFiles={appointmentFiles}
                  handleDeleteCondition={handleDeleteCondition}
                  recall={recall}
                  bgFamily={bgFamily}
                  bgPersonal={bgPersonal}
                  affiliationId={
                    patientData?.affiliations?.find(
                      (affiliation) => affiliation.status === "active"
                    )?.id
                  }
                  handleIncludeBeneficiary={handleIncludeBeneficiary}
                />
              )}
            </div>
          </div>
          <Snackbar
            open={openAlertImg}
            autoHideDuration={5000}
            onClose={handleCloseAlertImg}
          >
            <Alert onClose={handleCloseAlertImg} severity="success">
              {t("Success Profile Image Updated")}
            </Alert>
          </Snackbar>
        </DashboardPatient>
      }
    </>
  );
}

export default PatientEditProfile;
