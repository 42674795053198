import React from "react";
import FullCalendar, { padStart } from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";

export default function PlannerCalendar({
  events,
  setSlots,
  handleUnSelect,
  getDayName,
  readOnly = false,
}) {
  const adjustWeekDay = (day) => {
    return day === 0 ? 7 : day;
  };

  const handleSelect = (selectInfo) => {
    if (readOnly) return;
    const values = [
      //...slots, when we will save builk data we have to uncomment this rest line
      {
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        formattedTimes: {
          week_day: adjustWeekDay(selectInfo.start.getDay()),
          time_start:
            padStart(selectInfo.start.getHours(), 2) +
            ":" +
            selectInfo.start.getMinutes().toString().padEnd(2, "0"),
          time_end:
            padStart(selectInfo.end.getHours(), 2) +
            ":" +
            selectInfo.end.getMinutes().toString().padEnd(2, "0"),
        },
      },
    ];
    setSlots(values);
    // setEvents([...events, values[0]]);
  };

  const handleEventClick = (clickInfo) => {
    if (readOnly) return;
    handleUnSelect(clickInfo.event.id);
  };

  return (
    <div className="col-md-8">
      <FullCalendar
        plugins={[interactionPlugin, timeGridPlugin, dayGridPlugin]}
        firstDay={1}
        initialView="timeGridWeek"
        selectable={!readOnly}
        themeSystem="bootstrap"
        eventClick={handleEventClick}
        events={events}
        selectOverlap={(evn) => {
          return false;
        }}
        dayHeaderFormat={{
          weekday: "long",
        }}
        dayHeaderContent={(arg) => getDayName(arg.date)}
        select={handleSelect}
        headerToolbar={{
          left: "",
          right: "",
        }}
        longPressDelay={1000}
        selectLongPressDelay={1000}
        slotEventOverlap={false}
        // unselect={(e, view) => {
        //   console.log(e.view.getCurrentData());
        // }}
      />
    </div>
  );
}
