import React from "react";
import ReactDOM from "react-dom";
import "./styles/home.css";
import * as serviceWorker from "./serviceWorker";
import HomeRouter from "./components/HomeRouter";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
/** Redux configuration */
import { createStore } from "redux";
import appReducers from "./reducers";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
//import settings for transition
import "./i18n";
// fonts required for material-ui V5
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

//for Dev only
const reduxDevTool =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = createStore(appReducers, reduxDevTool);

const theme = createTheme({
  palette: {
    primary: {
      main: "#076cd8", // This is an main color of all
    },
    secondary: {
      main: "#0466c8", // secondary color for buttons etc
    },
  },
  //fontFamily: font // for the font family to whole application
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={2}>
        <Provider store={store}>
          <HomeRouter />
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
