import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  FaHeartbeat,
  FaAllergies,
  FaCapsules,
  FaClinicMedical,
  FaStethoscope,
  FaFilePrescription,
  FaMedkit,
  FaPeopleCarry,
  FaSyringe,
  FaFileMedicalAlt,
  FaUser,
  FaUserFriends,
} from "react-icons/fa";
import { GiScalpel } from "react-icons/gi";

// Import existing components
import HealthCondition from "./HealthCondition";
import Consultations from "./Consultations";
import Prescriptions from "./Prescriptions";
import Healthmeasurement from "./Healthmeasurements";
import DependantsPatient from "./EHR/DependantsPatient";
import VaccinesPatient from "./EHR/VaccinesPatient";
import AppointmentFiles from "./AppointmentFiles";
import BackgroundTable from "./BackgroundTable";

// Import modals
import DependantsModalForm from "./DependantsModalForm";
import VaccinesModalForm from "./VaccinesModalForm";
import HealthConditionModalForm from "./HealthConditionModalForm";
import HealthMeasurementModalForm from "./HealthMeasurementModalForm";
import EHRFilesModalForm from "./EHRFilesModalForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
  },
  accordion: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: "1.5rem",
    color: theme.palette.primary.main,
  },
  addButton: {
    marginBottom: theme.spacing(2),
  },
  summary: {
    backgroundColor: "#f0f0f0",
    "&.Mui-expanded": {
      backgroundColor: "#d0d0d0",
      color: theme.palette.text.primary,
    },
  },
}));

const sections = [
  {
    id: "health",
    icon: FaHeartbeat,
    title: "Health Conditions",
    type: "H-CONDITION",
  },
  { id: "allergies", icon: FaAllergies, title: "Allergies", type: "ALLERGIES" },
  { id: "vaccines", icon: FaSyringe, title: "Vaccines" },
  {
    id: "medications",
    icon: FaCapsules,
    title: "Medications",
    type: "MEDICATIONS",
  },
  {
    id: "hospitalizations",
    icon: FaClinicMedical,
    title: "Hospitalizations",
    type: "HOSPITALIZATIONS",
  },
  { id: "surgeries", icon: GiScalpel, title: "Surgery", type: "SURGERIES" },
  { id: "personal", icon: FaUser, title: "Personal Background" },
  { id: "family", icon: FaUserFriends, title: "Family Background" },
  { id: "consultations", icon: FaStethoscope, title: "Consultations" },
  { id: "prescriptions", icon: FaFilePrescription, title: "Prescriptions" },
  { id: "dependants", icon: FaPeopleCarry, title: "Family group" },
  { id: "monitor", icon: FaMedkit, title: "Health Monitor" },
  { id: "files", icon: FaFileMedicalAlt, title: "Exams and Results" },
];

export default function NewEHRVersion({
  healthConditions,
  consultationNotes,
  prescription,
  healthMeasurement,
  dependants,
  vaccines,
  appointmentFiles,
  bgFamily,
  bgPersonal,
  patientId,
  handleRefreshData,
  handleDeleteCondition,
  recall,
  affiliationId,
  handleIncludeBeneficiary,
  calcBodyMass,
  t,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = useState({ open: false, type: null });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenModal = (type) => {
    setOpenModal({ open: true, type });
  };

  const handleCloseModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const renderModalComponent = () => {
    switch (openModal.type) {
      case "H-CONDITION":
      case "ALLERGIES":
      case "MEDICATIONS":
      case "HOSPITALIZATIONS":
      case "SURGERIES":
        return (
          <HealthConditionModalForm
            open={openModal.open}
            modalTitle={t(
              sections.find((s) => s.type === openModal.type)?.title
            )}
            handleClose={handleCloseModal}
            patientId={patientId}
            conditionType={openModal.type}
            handleRefreshData={handleRefreshData}
          />
        );
      case "vaccines":
        return (
          <VaccinesModalForm
            open={openModal.open}
            handleClose={handleCloseModal}
            patientId={patientId}
            handleRefreshData={handleRefreshData}
          />
        );
      // Add other modal cases as needed
      default:
        return null;
    }
  };

  const renderSectionContent = (section) => {
    switch (section.id) {
      case "health":
      case "allergies":
      case "medications":
      case "hospitalizations":
      case "surgeries":
        return (
          <HealthCondition
            data={healthConditions}
            conditionType={section.type}
            tableTitle={t(section.title)}
            handleDeleteCondition={handleDeleteCondition}
            cie={section.id === "health"}
          />
        );
      case "vaccines":
        return (
          <VaccinesPatient data={vaccines} tableTitle={t(section.title)} />
        );
      case "consultations":
        return <Consultations data={consultationNotes} section="patient" />;
      case "prescriptions":
        return <Prescriptions data={prescription} />;
      case "monitor":
        return <Healthmeasurement data={healthMeasurement} type="pat" />;
      case "dependants":
        return (
          <DependantsPatient
            data={dependants}
            tableTitle={t(section.title)}
            handleIncludeBeneficiary={handleIncludeBeneficiary}
            affiliationId={affiliationId}
          />
        );
      case "files":
        return <AppointmentFiles data={appointmentFiles} />;
      case "personal":
        return (
          <BackgroundTable
            title={t(section.title)}
            data={bgPersonal}
            handleRefreshData={handleRefreshData}
            type="pat"
          />
        );
      case "family":
        return (
          <BackgroundTable
            title={t(section.title)}
            data={bgFamily}
            handleRefreshData={handleRefreshData}
            type="pat"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid container justify="space-between">
      <Grid item xs={12} sm={10} md={10} lg={10}>
        <div className={classes.root}>
          {sections.map((section) => (
            <Accordion
              key={section.id}
              expanded={expanded === section.id}
              onChange={handleChange(section.id)}
              className={classes.accordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.summary}
              >
                <Typography className={classes.heading}>
                  <section.icon className={classes.icon} />
                  {t(section.title)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column" spacing={2}>
                  {section.type && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.addButton}
                        startIcon={<AddCircleIcon />}
                        onClick={() => handleOpenModal(section.type)}
                      >
                        {t("New")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {renderSectionContent(section)}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          {renderModalComponent()}
        </div>
      </Grid>
    </Grid>
  );
}
