import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import LoadingSpin from "../utils/Loading";
import { useHistory, useParams } from "react-router-dom";
import ProviderProfileImage from "./ProviderProfileImage";
import "../../styles/customs.css";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { createTheme } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
//Redux
import { setUserImageProfile } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import UserLocalState from "../hooks/useUserState";

function VerificationStepProv() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [identification, setIdentification] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const person_type = "PAT";
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);
  const [country, setCountry] = useState("");
  const [nationality, setNationality] = useState("");
  const [patientId, setPatientId] = useState("");
  const [summary, setSummary] = useState("");
  const [password, setPassword] = useState("");
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const [data, setData] = useState("");
  const [jwtExpired, setJwtExpired] = useState("");
  const [openAlertImg, setOpenAlertImg] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  //jwt received in the email an placed on uri
  let { id } = useParams();
  const [specialty, setSpecialty] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState([]);
  const [countries, setCountries] = useState([]);
  const [medicalLicense, setMedicalLicense] = useState("");
  const [issuedBy, setIssuedBy] = useState("");

  //Distpach of Redux
  const distpach = useDispatch();

  //Extract the userState to Redux Glabal State
  const jwtStored = UserLocalState();

  const newUserImageState = useSelector((state) => state.newUserImage);

  const clearForm = () => {
    document.getElementById("patient-form").reset();
    setDateOfBirth("");
    setEmail("");
    setGender("");
    setLastname("");
    setfirstName("");
    setPhone("");
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // -------------------------PASSWORD----------------------------

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePaswword = (e) => {
    if (password != "") {
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/users/changePassword`,
        {
          token: id,
          newPassword: password,
        },
        {
          headers: { Authorization: "Bearer " + (id || jwtStored[0].jwt) },
        }
      )
        .then((res) => {
          swal({
            title: "Success!",
            text: "Password changed",
            icon: "success",
            button: "OK",
          });
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: "Failed!",
            text: "Error changing password",
            icon: "error",
            button: false,
          });
        });
    }
  };

  // -------------------------- ALERT -----------------------------

  const handleAlertImg = () => {
    setOpenAlertImg(true);
  };

  const handleCloseAlertImg = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertImg(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // -------------------------- IMAGE UPDATE CLOUDINARY --------------------

  const handleSaveProfileImg = (e) => {
    // console.log(userProfileImg.profileImg);
    if (newUserImageState.newProfileImg != undefined) {
      let formData = new FormData();
      formData.append("file_name", `${firstName}ProfileImg`);
      formData.append("created_by", firstName);
      formData.append("user_email", email);
      formData.append("file", newUserImageState.newProfileImg);
      console.log(formData);

      Axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL_BASE}/userImage/save`,
        data: formData,
        headers: {
          Authorization: "Bearer " + (id || jwtStored[0].jwt),
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          console.log(response);
          handleAlertImg();
          //Save response to Global State
          distpach(setUserImageProfile(response.data.dataSaved));
        })
        .catch(function (error) {
          //handle error
          console.log(error);
          swal(
            "Error!",
            "Something went wrong, please contact the Admin " + error,
            "error"
          );
        });
    } else {
      console.log("no hay imagen seleccionada");
    }
  };

  // ----------------------- PROFILE UPDATE -------------------------

  const handleClick = (e) => {
    if (
      email &&
      phone &&
      firstName &&
      lastname &&
      dateOfBirth &&
      gender &&
      patientId &&
      identification &&
      summary &&
      selectedSpecialty.length > 0
    ) {
      setLoading(true);
      const specialtyString = parseSpecialtyString(selectedSpecialty);
      if (specialtyString.length >= 150)
        return swal(
          "Error!",
          "Specialties overpass the maximum values, please select only the required values",
          "error"
        );

      console.log("specialtyString", specialtyString);
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/provider/updateAndConfirm`,
        {
          email,
          phone1: phone,
          firstName,
          lastname,
          dateOfBirth,
          gender,
          identificationType,
          person_type,
          identification,
          country,
          nationality,
          id: patientId,
          summary,
          specialty: specialtyString,
          medical_license: medicalLicense,
          issued_by: issuedBy,
        },
        {
          headers: { Authorization: "Bearer " + (id || jwtStored[0].jwt) }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoading(false);
          console.log("RESPONSE", response);
          swal({
            tittle: "Success!",
            text: `${
              id
                ? "Account Verified, Your request is under verification, please stay tuned"
                : "Account Information Updated"
            }`,
            icon: "success",
            button: `${id ? "Continue" : "Okay"}`,
          }).then((resOk) => {
            if (resOk && id) {
              history.push(`/admin/login`);
            } else {
              history.push(`/admin/dashboard`);
            }
          });
          setErrorMessage({
            error: "",
            visible: false,
            msgClass: "",
          });
          //clearForm();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setErrorMessage({
            error: error,
            visible: true,
            msgClass: "alert alert-danger d-flex justify-content-center",
          });
        });
    } else {
      setErrorMessage({
        error: "Error, Please fill the Form",
        visible: true,
        msgClass: "alert alert-danger ",
      });
    }
    handleSaveProfileImg();
  };

  // -------------------- USEEFFECT ----------------------------

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/provider/` +
        (id || jwtStored[0].jwt)
    )
      .then((res) => {
        setLoading(false);
        setData(res.data);
        //Set Data to Controls
        console.log(res.data);
        loadDataToControls(res.data);
        findImgByEmail(res.data[0].email);
        findSpecialties();
        findCountries();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  // ------------------ FIND IMAGE CLOUDINARY ------------------

  const findImgByEmail = (email) => {
    // if(email != ""){
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/userImage/findByEmail`, {
      headers: {
        Authorization: "Bearer " + (id || jwtStored[0].jwt),
        "Content-Type": "application/json",
      },
      params: { user_email: email },
    })
      .then((res) => {
        setImgLoading(false);
        distpach(
          setUserImageProfile({
            profileImg: res.data[0].path_resource,
            exists: true,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        setImgLoading(false);
        // changeUserProfileImg({ profileImg: "", exists: false });
        distpach(setUserImageProfile({ profileImg: "", exists: false }));
        setJwtExpired(err.response.status);
      });
  };

  //Specialties stored on BD
  const findSpecialties = async () => {
    try {
      const result = await Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/specialty/findAll`
      );
      if (result) {
        setSpecialty(result.data);
      }
    } catch (error) {
      console.error("Something wrong", error);
    }
  };

  //Find Countries On DB
  const findCountries = () => {
    // if(email != ""){
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/countries/findAll`)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ----------------------- STYLES CLASSES --------------------

  const useStyles = makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
    textField: {
      width: "100%",
    },
  }));

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#e33371",
      },
    },
  });
  const classes = useStyles();

  // ----------------------  FUNC SET STATES --------------------

  function loadDataToControls(data) {
    if (data) {
      const {
        email,
        firstName,
        lastname,
        identification,
        dateOfBirth,
        gender,
        country,
        nationality,
        id,
        phone1,
        identificationType,
        summary,
        medical_license,
        issued_by,
      } = data[0];
      setEmail(email);
      setfirstName(firstName);
      setLastname(lastname);
      setIdentification(identification);
      setDateOfBirth(dateOfBirth && dateOfBirth.split("T")[0]);
      setGender(gender);
      setCountry(country);
      setNationality(nationality);
      setPatientId(id);
      setPhone(phone1);
      setIdentificationType(identificationType);
      setSummary(summary);
      setIssuedBy(issued_by);
      setMedicalLicense(medical_license);
    }
  }

  //-----------------JWT EXPIRED -----------------------

  const handleJwtExpired = (e) => {
    swal({
      tittle: "Failed!",
      text: "Link expired, please contact suport",
      icon: "error",
      button: "Suport",
      closeOnClickOutside: false,
    }).then((resOk) => {
      if (resOk) {
        history.push(`/admin/login`);
      }
    });
  };

  //------------------ HANDLE CONFIRM ------------------

  const handleConfirm = (e) => {
    if (password && jwtStored[0]) {
      handlePaswword();
    } else {
      handlePaswword();
      handleClick(e);
    }
  };

  //to handle the selected specialities selected on control
  const handleChangeSpecialty = (sp) => {
    if (sp) {
      setSelectedSpecialty(sp);
    }
  };

  const parseSpecialtyString = (specialtiesArray) => {
    if (specialtiesArray) {
      let finalParsed = "";
      specialtiesArray.map((item, id) => {
        let index = id + 1;
        if (specialtiesArray.length != index) {
          finalParsed += item + ",";
        } else {
          finalParsed += item;
        }
      });
      return finalParsed;
    }
  };

  // ----------------------  RETURN -------------------

  return (
    <>
      {jwtExpired == 401 ? (
        handleJwtExpired()
      ) : (
        <>
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
            <div className="row d-flex justify-content-center">
              <div className="col-md-4 d-flex justify-content-center">
                <ProviderProfileImage email={email} imgLoading={imgLoading} />
              </div>
              <div className="col-md-9">
                <form className="row" id="patient-form">
                  <div className="form-group col-md-6">
                    <TextField
                      margin="normal"
                      label="First Name"
                      variant="outlined"
                      id="firstName"
                      fullWidth
                      onChange={(e) => setfirstName(e.target.value)}
                      required
                      value={firstName || ""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Last Name"
                      variant="outlined"
                      id="lastname"
                      onChange={(e) => setLastname(e.target.value)}
                      value={lastname || ""}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      required
                      margin="normal"
                      label="Your Email"
                      variant="outlined"
                      id="email"
                      value={email || ""}
                      autoComplete="email"
                      fullWidth
                      placeholder="name@example.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-8">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Phone Number"
                      variant="outlined"
                      id="phoneNumber"
                      value={phone || ""}
                      placeholder="888823456"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-4 ">
                    <TextField
                      id="country"
                      required
                      select
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      placeholder="Select"
                      label="Country"
                      value={country || "select"}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      {countries &&
                        countries.map((singleCountry) => (
                          <MenuItem
                            key={singleCountry.id}
                            value={singleCountry.code}
                          >
                            {singleCountry.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                  <div className="form-group col-md-3">
                    <TextField
                      id="identificationType"
                      required
                      select
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      placeholder="Select"
                      label="Identification Type"
                      onChange={(e) => setIdentificationType(e.target.value)}
                      value={identificationType || "select"}
                    >
                      <MenuItem value="DNI">DNI</MenuItem>
                      <MenuItem value="PASSPORT">Passport</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </TextField>
                  </div>

                  <div className="form-group col-md-9">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Identification/DNI"
                      variant="outlined"
                      id="identification"
                      onChange={(e) => setIdentification(e.target.value)}
                      value={identification || ""}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      label="Date Of Birth"
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      value={dateOfBirth || ""}
                    />
                  </div>
                  <div className="form-group col-md-8">
                    <FormControl variant="outlined" className={classes.form}>
                      <InputLabel required id="gender">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="gender"
                        id="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        label="Gender"
                      >
                        <MenuItem value={""}>None</MenuItem>
                        <MenuItem value={"MALE"}>Male</MenuItem>
                        <MenuItem value={"FEMALE"}>Female</MenuItem>
                        <MenuItem value={"OTHER"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/* License and Issued By */}

                  <div className="form-group col-md-6">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Medical License"
                      variant="outlined"
                      id="medical-license"
                      onChange={(e) => setMedicalLicense(e.target.value)}
                      value={medicalLicense || ""}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Issued By"
                      variant="outlined"
                      id="issued-by"
                      onChange={(e) => setIssuedBy(e.target.value)}
                      value={issuedBy || ""}
                    />
                  </div>

                  {/* End License and Issued By */}
                  <div className="form-group col-md-12">
                    <TextField
                      id="summary"
                      label="Professional Summary"
                      multiline
                      fullWidth
                      rows={5}
                      value={summary}
                      variant="outlined"
                      onChange={(e) => setSummary(e.target.value)}
                    />
                  </div>
                  {/* Specialties */}
                  <div className="form-group col-md-12">
                    <FormControl className="col-md-12">
                      <InputLabel id="specialty-label">Specialty</InputLabel>
                      <Select
                        variant="outlined"
                        labelId="specialty-label"
                        id="specialty"
                        multiple
                        value={selectedSpecialty}
                        onChange={(e) => handleChangeSpecialty(e.target.value)}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className="d-flex flex-wrap">
                            {selected.map((item, idx) => (
                              <Chip
                                key={item}
                                label={item}
                                className="m-1 bg-blue"
                              />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {specialty.map((el) => (
                          <MenuItem
                            key={el.id}
                            value={el.speciality_name}
                            //style={getStyles(name, personName, theme)}
                          >
                            {el.speciality_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-group col-md-12">
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={110}
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-12 mt-3 d-flex justify-content-center ">
                    {errorMessage.visible && (
                      <div className={errorMessage.msgClass} role="alert">
                        {errorMessage.error}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-center ">
                <div className="p-3">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={handleConfirm}
                  >
                    Save
                  </Button>
                </div>

                <div className="p-3">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={(e) => {
                      if (id) {
                        history.push(`/admin/login`);
                      } else {
                        history.push(`/admin/dashboard`);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <LoadingSpin type="spokes" color="#1d3557" />
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={openAlertImg}
            autoHideDuration={5000}
            onClose={handleCloseAlertImg}
          >
            <Alert onClose={handleCloseAlertImg} severity="success">
              Success Profile Image Updated
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}

export default VerificationStepProv;
